import { clsx } from "clsx";
import { InputHTMLAttributes, useState } from "react";

import { Icon } from "../../icon";

export const FileInputField = (
  props: InputHTMLAttributes<HTMLInputElement>,
) => (
  <input
    {...props}
    className="absolute top-0 right-0 border-0 h-0 w-0 opacity-0 overflow-hidden outline-none"
  />
);

type FileUploadProps = {
  id: string;
  errorId: string;
  error?: string;
  sizeLimit: string;
  onSelect: (files?: FileList) => void;
  isMultiFile?: boolean;
  disabled?: boolean;
};

export const FileUpload = ({
  id,
  error,
  errorId,
  sizeLimit,
  onSelect,
  isMultiFile = true,
  disabled,
}: FileUploadProps) => {
  const [dragging, setDragging] = useState(false);

  return (
    <label
      className={clsx(
        "m-0 border-2 border-dashed focus-within:shadow-[0px_0px_0px_4px_rgba(174,211,254,0.6)] focus-within:border-blue-560 rounded outline-none cursor-pointer flex gap-small flex-col items-center px-8 py-16",
        dragging ? "bg-blue-60 border-blue-560" : "bg-gray-100 border-gray-300",
        error &&
          "border-red-550 shadow-[0px_0px_0px_4px_rgba(250,186,184,0.6)]",
        disabled && "text-gray-300",
      )}
      htmlFor={id}
      aria-invalid={!!error}
      aria-errormessage={error ? errorId : undefined}
      onDragOver={(e) => {
        e.preventDefault();
        setDragging(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setDragging(false);
      }}
      onDrop={(e) => {
        e.preventDefault();
        setDragging(false);
        onSelect(e.dataTransfer.files);
      }}
    >
      <div className="w-[3.2rem] h-[3.2rem] bg-black/10 rounded flex justify-center items-center">
        <Icon name="solidSquareUp" className="w-[1.4rem]" />
      </div>
      <p>
        <span
          className={clsx(
            "text-ui font-normal",
            disabled ? "text-gray-300" : "text-blue-560",
          )}
        >
          Browse
        </span>{" "}
        <span className="text-ui font-semibold">
          or drag {isMultiFile ? "files" : "a file"} here
        </span>
      </p>
      <p className="text-caption font-normal">{sizeLimit}</p>
    </label>
  );
};
