import {
  autoUpdate,
  flip,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
} from "@floating-ui/react";
import clsx from "clsx";
import { MouseEvent, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

import { BaseInput, BaseInputProps } from "./base";
import { Dropdown } from "../dropdown";
import { Label } from "../label";
import { Tag } from "../tag";
import { Tooltip } from "../tooltip";

type Option<V extends any = any> = { label: string; value: V };
type GroupedOption<V extends any = any> = {
  heading: string;
  items: Option<V>[];
};

export type SelectProps<V extends any = any> = {
  value: V | V[];
  onChange: (value: V | V[]) => void;
  options: Option<V>[] | Option<V>[][] | GroupedOption<V>[];
  valueComparer?: (currentValue: V, valueToCompare: V) => boolean;
  isMultiple?: boolean;
  empty?: string;
  maxItems?: number;
  className?: string;
} & Omit<
  BaseInputProps,
  "leftIcon" | "rightButtonIcon" | "onRightButtonClick" | "value" | "onChange"
>;

export const Select = <V extends any = any>(props: SelectProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const [filterText, setFilterText] = useState<string | undefined>(undefined);
  const defaultComparer = (a: V, b: V) => a === b;

  const { valueComparer, mandatory, maxItems, isMultiple, ...rest } = props;

  const options = useFloating({
    placement: "bottom",
    open: showOptions,
    onOpenChange: setShowOptions,
    middleware: [shift({ padding: 10 }), flip({ fallbackPlacements: ["top"] })],
    whileElementsMounted: autoUpdate,
  });

  const optionsInteractions = useInteractions([
    useDismiss(options.context),
    useFocus(options.context),
  ]);

  useEffect(() => {
    if (!showOptions) setFilterText(undefined);
  }, [showOptions]);

  const getOptionsType = () => {
    if ((props.options[0] as GroupedOption)?.heading) return "grouped";
    if (Array.isArray(props.options[0])) return "divided";
    return "default";
  };
  const optionsType = getOptionsType();

  const compare = (o: { value: V }, value: V) =>
    (valueComparer || defaultComparer)(value, o.value);

  const getSelectedLabel = (value: V) => {
    const optionsToUse =
      optionsType === "grouped"
        ? props.options.flatMap((o) => (o as GroupedOption).items)
        : (props.options as Option[] | Option[][]).flat();
    return optionsToUse.find((o) => compare(o, value))?.label || "";
  };

  const removeValue = (value: V) =>
    props.onChange(props.value.filter((v: V) => !compare({ value }, v)));
  const mapDropdownItems = () => {
    const filterOptions = (o: Option) =>
      filterText
        ? o.label.toLowerCase().includes(filterText.toLowerCase())
        : true;
    const mapOption = (o: Option) => {
      const isExisting = !!(
        isMultiple && props.value?.some((v: V) => compare(o, v))
      );

      return {
        ...o,
        ...(isMultiple
          ? { checked: isExisting }
          : { active: compare(o, props.value) }),
        onClick: (e: MouseEvent) => {
          e.preventDefault();
          if (isMultiple) {
            if (isExisting) {
              removeValue(o.value);
            } else {
              props.onChange([...(props.value || []), o.value]);
            }
          } else {
            props.onChange(o.value);
            setFilterText(undefined);
            setShowOptions(false);
          }
        },
      };
    };
    const notFoundText = props.empty || "No items found";

    if (optionsType === "grouped") {
      const groupedOptions = props.options as GroupedOption[];
      const items = groupedOptions
        .filter((g) => (filterText ? g.items.some(filterOptions) : true))
        .map((g) => ({
          ...g,
          items: g.items.filter(filterOptions).map(mapOption),
        }));
      return items.length
        ? items
        : [{ heading: "", items: [{ label: notFoundText }] }];
    }

    if (optionsType === "divided") {
      const dividedOptions = props.options as Option[][];
      const items = dividedOptions
        .filter((ops) => (filterText ? ops.some(filterOptions) : true))
        .map((ops) => ops.filter(filterOptions).map(mapOption));
      return items.length ? items : [[{ label: notFoundText }]];
    }

    const regularOptions = props.options as Option[];
    const items = regularOptions.filter(filterOptions).map(mapOption);
    return items.length ? items : [{ label: notFoundText }];
  };

  return (
    <div className={twMerge(clsx("relative", props.className))}>
      <Label
        text={props.label}
        mandatory={mandatory}
        disabled={props.disabled}
        name={props.id}
        error={!!props.error}
        message={props.message}
      >
        <div
          ref={options.refs.setReference}
          {...optionsInteractions.getReferenceProps()}
        >
          <BaseInput
            {...rest}
            dataTestId={rest.dataTestId || `select-${props.id}`}
            contentOverride={
              isMultiple && props.value?.length ? (
                <div className="flex flex-wrap gap-small min-h-[2.5rem]">
                  {props.value.slice(0, maxItems).map((v: V) => {
                    const label = getSelectedLabel(v);
                    return (
                      <Tag
                        key={label}
                        variant="compact"
                        text={label}
                        onClose={(e) => {
                          e.preventDefault();
                          removeValue(v);
                        }}
                      />
                    );
                  })}
                  {maxItems && props.value.length > maxItems && (
                    <Tooltip
                      placement="top"
                      text={props.value
                        .slice(maxItems)
                        .map(getSelectedLabel)
                        .join(", ")}
                    >
                      <span className="flex justify-center items-center font-semibold px-[0.5rem] border border-neutral-400 rounded-[0.4rem]">
                        +{props.value.length - maxItems}
                      </span>
                    </Tooltip>
                  )}
                </div>
              ) : undefined
            }
            className={clsx(isMultiple && "caret-white/0")}
            value={
              isMultiple
                ? undefined
                : filterText ?? getSelectedLabel(props.value)
            }
            onChange={(event) => setFilterText(event.target.value)}
            onRightButtonClick={() => setShowOptions((current) => !current)}
            rightButtonIcon="regularAngleDown"
          />
        </div>
        {showOptions && (
          <div
            className="z-1 w-full"
            ref={options.refs.setFloating}
            style={options.floatingStyles}
            data-testid={`${props.dataTestId || "select"}-options`}
            {...optionsInteractions.getFloatingProps()}
          >
            <Dropdown
              items={mapDropdownItems()}
              variant={optionsType}
              searchValue={isMultiple ? filterText : undefined}
              onSearch={isMultiple ? setFilterText : undefined}
            />
          </div>
        )}
      </Label>
    </div>
  );
};
