import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import clsx from "clsx";
import { useEffect, useState } from "react";

import {
  FieldComponentType,
  fieldFallbackLabel,
} from "@smart/bridge-intake-components-dom";
import { Card, EditableText, Icon } from "@smart/itops-sb-design-system-dom";

import { FieldTypeIndicator } from "./field-type-indicator";
import { fieldComponentIcons } from "../../constants";
import {
  ConditionActions,
  FieldTemplate,
  GqlFieldValues,
  GqlGroupValues,
  GqlMatterLayout,
  GqlMatterType,
  LoadMatterFields,
  OnDeleteField,
  OnFieldTypeChange,
  OnUpdateField,
} from "../../types";
import { Actions } from "../actions";
import { FieldBox } from "../field-box";

type FieldCardProps = {
  loading?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  isShrinking?: boolean;
  isDragging?: boolean;
  editing?: boolean;
  onUpdateField: OnUpdateField;
  onDeleteField: OnDeleteField;
  matterLayouts?: GqlMatterLayout[];
  matterTypes?: GqlMatterType[];
  loadMatterFields: LoadMatterFields;
  field: GqlFieldValues;
  conditionActions: ConditionActions;
  groupLayout?: GqlGroupValues["layout"] | null;
  groupMatterField?: GqlGroupValues["field"] | null;
  onFieldTypeChange: OnFieldTypeChange;
  excludingFieldTemplates: FieldTemplate[];
};

export const FieldCard = ({
  loading,
  isShrinking,
  onUpdateField,
  onDeleteField,
  dragHandleProps,
  editing,
  matterLayouts,
  matterTypes,
  loadMatterFields,
  field,
  conditionActions,
  groupLayout,
  groupMatterField,
  onFieldTypeChange,
  excludingFieldTemplates,
}: FieldCardProps) => {
  const {
    type,
    label: question,
    description,
    mandatory,
    field: matterField,
    layout,
  } = field;

  const fieldType = matterField && layout ? "mapped" : type;
  const [editedQuestion, setEditedQuestion] = useState(
    question || fieldFallbackLabel[fieldType],
  );
  const [editedDescription, setEditedDescription] = useState(description);
  const [descriptionVisible, setDescriptionVisible] = useState(!!description);
  const [isEditing, setIsEditing] = useState(editing);

  const handleEditComplete = () =>
    onUpdateField({
      field,
      updated: {
        label: editedQuestion,
        description: editedDescription ?? undefined,
      },
    });

  useEffect(() => {
    setIsEditing(editing);
  }, [editing]);

  const onChangeFieldType = async (newFieldType: FieldComponentType) => {
    await onFieldTypeChange({ field, newFieldType });
  };

  return (
    <div className="flex flex-col justify-center grow w-full content-center flex-wrap">
      <Card
        className="border-none p-[1.6rem] pt-[1.1rem] pr-[3.2rem] shadow-none"
        current={isEditing}
        flow="row"
        loading={loading}
      >
        <div className="flex items-center justify-center">
          <div className="my-4 mr-5 ml-0" {...dragHandleProps}>
            <Icon
              name="solidGripDotsVertical"
              className="w-[1.1rem] h-[2.2rem]"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center w-full overflow-hidden">
          <div className="flex items-start w-full mt-2">
            <div className="flex flex-col items-start justify-center grow relative w-full overflow-hidden">
              <EditableText
                text={editedQuestion}
                onChange={setEditedQuestion}
                onComplete={handleEditComplete}
                isMandatory={mandatory}
                editing={isEditing}
                onEditing={setIsEditing}
                fontWeight="semibold"
                isEditor
              />
              {descriptionVisible && (
                <EditableText
                  text={editedDescription}
                  onChange={setEditedDescription}
                  onComplete={handleEditComplete}
                  placeholder="Description"
                  isEditor
                />
              )}
            </div>
            <Actions
              type={type}
              descriptionVisible={descriptionVisible}
              mandatory={mandatory}
              onToggleDescription={async (checked) => {
                setDescriptionVisible(checked);
                if (!checked) {
                  setEditedDescription("");
                  await onUpdateField({ field, updated: { description: "" } });
                }
              }}
              onToggleMandatory={async (isMandatory) => {
                await onUpdateField({
                  field,
                  updated: { mandatory: isMandatory },
                });
              }}
              onDelete={() => onDeleteField(field)}
            />
          </div>
          {!isShrinking && (
            <FieldBox field={field} onUpdateField={onUpdateField} />
          )}
          <div
            className="flex items-center mt-2 justify-between gap-4"
            data-testid="field-card-footer"
          >
            <FieldTypeIndicator
              fieldType={type}
              label={fieldFallbackLabel[type]}
              icon={fieldComponentIcons[fieldType].name}
              matterField={matterField}
              layout={layout}
              matterLayouts={matterLayouts}
              matterTypes={matterTypes}
              loadMatterFields={loadMatterFields}
              groupMatterField={groupMatterField}
              groupLayout={groupLayout}
              onChangeFieldType={onChangeFieldType}
              excludingFieldTemplates={excludingFieldTemplates}
            />
            {type !== "info" && (
              <button
                className={clsx(
                  "flex justify-center items-center  w-[2.4rem] h-[2.4rem] rounded-small cursor-pointer",
                  conditionActions.checkHasConditions(field.uri)
                    ? "bg-orange-50"
                    : "bg-neutral-50",
                )}
                onClick={() => conditionActions.setEditingCondition(field)}
                type="button"
              >
                <Icon
                  name="solidArrowsSplitUpAndLeft"
                  className="w-[1.1rem] h-4"
                />
              </button>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};
