import { clsx } from "clsx";
import { ReactNode } from "react";

import { useGlobalKeyboardShortcut } from "@smart/itops-hooks-dom";

import { ModalFooter, ModalFooterProps } from "./footer";
import { ModalHeader, ModalHeaderProps } from "./header";

export type ModalProps = {
  children?: ReactNode;
  className?: string;
  closeOptions?: {
    escapeKey?: boolean;
    clickOutside?: boolean;
  };
  footer?: ModalFooterProps;
  header?: ModalHeaderProps;
  loading?: boolean;
  open: boolean;
  onClose?: () => void;
  variant?: "nonScrollable" | "scrollable";
  size?: "small" | "default" | "large" | "xLarge";
  dataTestId?: string;
};

export const Modal = ({
  children,
  className,
  closeOptions,
  footer,
  header,
  loading,
  open,
  onClose,
  variant = "nonScrollable",
  size = "default",
  dataTestId,
}: ModalProps) => {
  const closeOptionsToUse = {
    escapeKey: true,
    clickOutside: true,
    ...closeOptions,
  };
  useGlobalKeyboardShortcut({
    key: "Escape",
    fn: closeOptionsToUse.escapeKey && onClose ? () => onClose() : undefined,
  });

  if (!open) return null;

  const widths = {
    small: "w-[36.8rem] max-w-[min(36.8rem,95vw)]",
    default: "w-[64rem] max-w-[min(64rem,95vw)]",
    large: "w-[96rem] max-w-[min(96rem,95vw)]",
    xLarge: "w-[130.4rem] max-w-[min(130.4rem,95vw)]",
  };
  const variants = {
    nonScrollable: "px-[2.4rem] py-[1.6rem]",
    scrollable: "px-[2.4rem] py-[3.2rem] bg-neutral-50",
  };

  return (
    <div
      className={clsx(
        "fixed inset-0 z-modal flex justify-center items-center",
        className,
      )}
      data-testid={dataTestId}
    >
      <button
        type="button"
        aria-label="Close Modal - Outside"
        className="absolute inset-0 bg-black/30 w-full h-full m-0 p-0 border-0"
        onClick={() =>
          closeOptionsToUse.clickOutside && onClose ? onClose() : undefined
        }
      />
      <div
        className={clsx(
          "modal-container relative bg-white rounded shadow-modal max-h-[calc(100vh-12.8rem)] flex flex-col flex-nowrap",
          widths[size],
        )}
      >
        {header && (
          <ModalHeader {...header} onClose={header.onClose || onClose} />
        )}
        <div
          className={clsx(
            "flex gap-medium flex-col flex-1 relative text-ui font-inter border-solid border-y border-neutral-100",
            variants[variant],
          )}
        >
          {children}
          {loading && <div className="fixed inset-0 cursor-wait bg-black/5" />}
        </div>
        {footer && <ModalFooter {...footer} loading={loading} />}
      </div>
    </div>
  );
};
