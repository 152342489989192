import { clsx } from "clsx";

import { fileTypes } from "@smart/bridge-images-dom";
import { isKeyOf } from "@smart/itops-utils-basic";
import { useLoadMatterFiles } from "@smart/manage-gql-client-dom";

import { Icon } from "../icon";

export type FileItemType = NonNullable<
  ReturnType<typeof useLoadMatterFiles>["result"]
>[number];

type FileItemProps = {
  fileItem: FileItemType;
  selectedFileItem: FileItemType | undefined;
  setSelectedFileItem: (selected: FileItemType) => void;
  setViewingFolderId: (viewing: string | "root" | undefined) => void;
};

export const FileItem = ({
  fileItem,
  selectedFileItem,
  setSelectedFileItem,
  setViewingFolderId,
}: FileItemProps) => {
  const extension = (fileItem?.fileExtension || "").replace(".", "");
  const isFolder = fileItem.type === "folder";
  const isSelected = selectedFileItem?.id === fileItem.id;
  return (
    <div
      className={clsx(
        "flex gap-4 p-2 cursor-pointer items-center",
        isSelected ? "bg-blue-160" : "hover:bg-blue-60",
      )}
      role="button"
      tabIndex={0}
      onClick={() => {
        if (isFolder) {
          setViewingFolderId(fileItem.id);
        } else {
          setSelectedFileItem(fileItem);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          if (isFolder) {
            setViewingFolderId(fileItem.id);
          } else {
            setSelectedFileItem(fileItem);
          }
        }
      }}
    >
      {isFolder ? (
        <div className="p-2 flex items-center justify-center rounded-small">
          <Icon name="solidFolder" className="text-yellow-450" />
        </div>
      ) : (
        <div
          className="bg-contain bg-center bg-no-repeat w-10 h-10 mr-1 flex-none basis-10"
          style={{
            backgroundImage: `url(${isKeyOf(fileTypes)(extension) ? fileTypes[extension] : fileTypes.txt})`,
          }}
        />
      )}
      <span className={clsx("text-ui", isFolder && "underline")}>
        {fileItem.name}
      </span>
    </div>
  );
};
