import { FieldType } from "@smart/bridge-types-basic";
import { Button, ContextMenu } from "@smart/itops-sb-design-system-dom";

type ActionsProps = {
  type: FieldType;
  descriptionVisible: boolean;
  mandatory: boolean;
  onToggleDescription: (visible: boolean) => void;
  onToggleMandatory: (mandatory: boolean) => void;
  onDelete: () => void;
};

export const Actions = ({
  type,
  descriptionVisible,
  mandatory,
  onToggleDescription,
  onToggleMandatory,
  onDelete,
}: ActionsProps) => (
  <div className="flex gap-small">
    <ContextMenu
      id="field-actions"
      placement="bottom"
      button={{
        leftIcon: "solidEllipsis",
        variant: "secondarySubtle",
        className: "w-[2.4rem] h-[2.4rem]",
      }}
      items={[
        {
          label: "Description text",
          checked: descriptionVisible,
          onClick: () => onToggleDescription(!descriptionVisible),
        },
        {
          label: "Mandatory field",
          checked: mandatory,
          onClick: () => onToggleMandatory(!mandatory),
          hidden: type === "info",
        },
      ]}
    />
    <Button
      variant="secondarySubtle"
      leftIcon="regularTrashCan"
      onClick={onDelete}
      className="w-[2.4rem] h-[2.4rem]"
      data-testid="delete-field"
    />
  </div>
);
