import styled from "@emotion/styled";
import { capitalCase } from "change-case";

import { Locale } from "@smart/bridge-types-basic";

const DatePartsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
  padding: 0.5rem;
`;

const DatePart = styled.div<{ isYear?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .box {
    height: 5rem;
    width: ${(props) => (props.isYear ? "10rem" : "6rem")};
    border: 1px solid ${(props) => props.theme.scheme.grey.r35};
    border-radius: 0.5rem;
  }

  span {
    font-size: ${(props) => props.theme.fontSize.base};
    color: ${(props) => props.theme.scheme.grey.r60};
  }
`;
export const DateDisplayer = ({
  dateFormat,
}: {
  dateFormat: Locale["dateFormat"];
}) => (
  <DatePartsContainer>
    {dateFormat.map((part) => (
      <DatePart isYear={part === "year"} key={part}>
        <span>{capitalCase(part)}</span>
        <div className="box" />
      </DatePart>
    ))}
  </DatePartsContainer>
);
