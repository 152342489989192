import clsx from "clsx";

import { defaultSizeLimit } from "@smart/bridge-intake-components-dom";
import { loadLocale } from "@smart/itops-locale-dom";
import { FileUpload } from "@smart/itops-ui-dom";

import { Appointment } from "./appointment";
import { DateDisplayer } from "./date";
import { EditChoice } from "./edit-choice";
import { Payment } from "./payment";
import { GqlFieldValues, OnUpdateField } from "../types";

type FieldBoxProps = {
  field: GqlFieldValues;
  onUpdateField: OnUpdateField;
};

export const FieldBox = ({ field, onUpdateField }: FieldBoxProps) => {
  const { dateFormat } = loadLocale();
  const getBoxClassNames = (size: "small" | "large") =>
    clsx(
      "border border-solid border-neutral-200 rounded my-1 mx-0 bg-neutral-20",
      size === "small" ? "h-16" : "h-[8rem]",
    );

  switch (field.type) {
    case "text":
    case "number":
    case "email":
    case "address":
    case "currency":
    case "phoneNumber":
      return (
        <div
          className={getBoxClassNames("small")}
          data-testid="small-text-box"
        />
      );
    case "file":
      return (
        <FileUpload
          id="file-upload"
          errorId="file-upload"
          sizeLimit={defaultSizeLimit}
          onSelect={() => {}}
        />
      );
    case "multilineText":
      return (
        <div
          className={getBoxClassNames("large")}
          data-testid="large-text-box"
        />
      );
    case "date":
      return <DateDisplayer dateFormat={dateFormat} />;
    case "choice":
    case "checkbox":
      return <EditChoice field={field} onUpdateField={onUpdateField} />;
    case "appointment":
      return <Appointment field={field} onUpdateField={onUpdateField} />;
    case "payment":
      return <Payment field={field} onUpdateField={onUpdateField} />;
    default:
      return null;
  }
};
