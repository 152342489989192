import clsx from "clsx";

import {
  FieldComponentType,
  fieldFallbackLabel,
} from "@smart/bridge-intake-components-dom";
import {
  Avatar,
  ContextMenu,
  IconName,
} from "@smart/itops-sb-design-system-dom";

import {
  fieldComponentIcons,
  fieldComponentNames,
  fieldTemplates,
} from "../../constants";
import {
  FieldTemplate,
  GqlFieldValues,
  GqlGroupValues,
  GqlMatterLayout,
  GqlMatterType,
  LoadMatterFields,
} from "../../types";
import { MappedFieldPathDisplay } from "../mapped-field-path-display";

type FieldTypeIndicatorProps = {
  label: string;
  icon: IconName;
  layout?: GqlFieldValues["layout"];
  matterField?: GqlFieldValues["field"];
  matterLayouts?: GqlMatterLayout[];
  matterTypes?: GqlMatterType[];
  loadMatterFields: LoadMatterFields;
  groupLayout?: GqlGroupValues["layout"] | null;
  groupMatterField?: GqlGroupValues["field"] | null;
  onChangeFieldType: (newFieldType: FieldComponentType) => Promise<void>;
  excludingFieldTemplates: FieldTemplate[];
  fieldType: FieldComponentType;
};

const FieldTypeIndicator = ({
  label,
  icon,
  matterField,
  layout,
  matterLayouts,
  matterTypes,
  loadMatterFields,
  groupMatterField,
  groupLayout,
  onChangeFieldType,
  excludingFieldTemplates,
  fieldType,
}: FieldTypeIndicatorProps) => {
  // Leaving this context menu here as we will need to implement it.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const menu = () => (
    <ContextMenu
      id="change-field-type"
      className="change-field-type"
      placement="bottom-start"
      fallbackPlacements={["bottom-start"]}
      button={{
        leftIcon: icon,
        text: label,
        className: "change-field-type-button",
        variant: "secondarySubtle",
      }}
      items={fieldTemplates
        .filter(
          (ft) =>
            ft.type !== "group" &&
            !excludingFieldTemplates.find(({ type }) => type === ft.type),
        )
        .map((template) => ({
          label: fieldFallbackLabel[template.type],
          leftIcon: fieldComponentIcons[template.type].name,
          onClick: async () => onChangeFieldType(template.type),
        }))}
    />
  );

  return (
    <div className="flex items-center gap-4">
      <div className={clsx("flex items-center gap-small py-[0.4rem] rounded")}>
        <Avatar
          size={24}
          icon={fieldComponentIcons[fieldType].name}
          bgColor={fieldComponentIcons[fieldType].bgColor}
        />
        <span className="text-ui font-semibold">
          {fieldComponentNames[fieldType]}
        </span>
      </div>
      {matterField && layout && (
        <MappedFieldPathDisplay
          field={matterField}
          layout={layout}
          matterLayouts={matterLayouts}
          matterTypes={matterTypes}
          loadMatterFields={loadMatterFields}
          groupMatterField={groupMatterField}
          groupLayout={groupLayout}
        />
      )}
    </div>
  );
};

export { FieldTypeIndicator, FieldTypeIndicatorProps };
